.comments-container {
  display: grid;
  grid-gap: 20px;
}
.comments-container .comment_added {
  margin-bottom: 10px;
  color: #02b10e;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
.comments-container .comment-card {
  display: grid;
  grid-gap: 10px;
  word-break: break-word;
}
.comments-container .comment-card .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comments-container .comment-card .replies {
  display: grid;
  margin-left: 14px;
  grid-gap: 16px;
}
.comments-container .comment-card .replies .reply-item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
}
.comments-container .comment-card .replies .reply-item .sc-reply {
  color: #000000;
  font-size: calc(16px + var(--big-fs));
  line-height: 1;
}
.comments-container .comment-card .replies .reply-item .replied-comment {
  display: grid;
  grid-gap: 10px;
}
@media (max-width: 768px) {
  .comments-container .comment-card .replies {
    margin-left: 0;
  }
}
.comments-container .pagination-container {
  width: 100%;
  grid-gap: 10px;
}

.comments-list {
  display: grid;
  grid-gap: 20px;
}
.comments-list .title img {
  width: 20px;
}
.comments-list .post {
  width: 100%;
  grid-gap: 10px;
  height: fit-content;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
.comments-list .post .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  overflow: hidden;
}
.comments-list .post .title b {
  white-space: nowrap;
}
.comments-list .post .title a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.comments-list .post .title > div {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.comments-list .post .title span.primary {
  color: #001f55;
}
.comments-list .post .title .date {
  white-space: nowrap;
  color: #949496;
  font-weight: normal;
}

.comments-container .post {
  display: grid;
}
