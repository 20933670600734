.private-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  min-height: 50vh;
}
.private-page-container .header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 30px 20px;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
}
.private-page-container .header h1 {
  font-weight: bold;
  font-size: calc(18px + var(--big-fs));
  line-height: 24px;
}
.private-page-container .header .link-back {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.private-page-container .header .link-back i {
  font-size: calc(24px + var(--big-fs));
  line-height: 1;
}
.private-page-container .submit-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.private-page-container .submit-form.submitted {
  flex: 1;
  justify-content: center;
}
.private-page-container .submit-form.submitted p {
  max-width: 260px;
  width: auto;
  text-align: center;
  font-weight: bold;
  padding: 200px 0;
  font-size: calc(16px + var(--big-fs));
  line-height: 22px;
}
@media (max-width: 1024px) {
  .private-page-container .submit-form.submitted p {
    padding: 160px 0;
  }
}
.private-page-container .page-desc {
  margin: 40px 0 36px;
  max-width: 290px;
  width: 100%;
  text-align: center;
  font-weight: bold;
}
.private-page-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  grid-gap: 10px;
}
.private-page-container form .input-wrapper {
  width: 210px;
}
.private-page-container form .form-control {
  width: 100%;
}
.private-page-container form .btn {
  width: 210px;
}
.private-page-container form .error {
  display: block;
  min-height: 20px;
  color: #ff4869;
  padding: 0 12px;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
.private-page-container form .forgot {
  margin-top: 4px;
}
.private-page-container form .form-submit {
  margin-top: 20px;
}
.private-page-container form p {
  max-width: 340px;
  width: auto;
  text-align: center;
}
.private-page-container form .customer-title {
  margin-top: 30px;
}
.private-page-container form .input-wrapper {
  position: relative;
}
.private-page-container.registration form {
  width: 440px;
}
.private-page-container.registration .sc-input-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 20px;
  margin-top: 46px;
}
.private-page-container.registration .sc-checkbox-group {
  display: grid;
  grid-gap: 5px;
  width: 100%;
}
.private-page-container.registration .sc-checkbox-group .error {
  padding-left: 22px;
  min-height: unset;
}
.private-page-container.registration .sc-checkbox-group .form-check:not(:last-of-type) {
  margin-bottom: 9px;
}
.private-page-container.registration .sc-checkbox-group a {
  text-decoration: underline;
  color: #d61f79;
}
.private-page-container.registration .sc-checkbox-group a:hover {
  color: #c01c6c;
}
.private-page-container.registration .sc-checkbox-group a:active {
  color: #a91960;
}
.private-page-container.registration .sc-radio-group {
  display: grid;
  grid-gap: 5px;
  width: 100%;
}
.private-page-container.registration .sc-radio-group .form-check {
  position: relative;
  overflow: hidden;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding-left: 19px;
}
.private-page-container.registration .sc-radio-group .form-check input {
  display: flex;
  position: relative;
  min-width: 16px;
  min-height: 16px;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.private-page-container.registration .sc-radio-group .form-check label {
  width: calc(100% + 40px);
  padding: 11px 15px 11px 45px;
  margin-left: -40px;
}
.private-page-container.registration .sc-radio-group label {
  color: #000000;
}
.private-page-container.registration .captcha-wrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px 12px;
  width: 100%;
}
.private-page-container.registration .captcha-wrapper img {
  width: 92px;
  height: 34px;
}
.private-page-container.registration .captcha-wrapper input {
  width: 130px;
}
.private-page-container.registration .captcha-wrapper .error {
  flex-basis: 100%;
}
.private-page-container.registration input,
.private-page-container.registration label {
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.private-page-container.success-message .content {
  max-width: 440px;
  text-align: center;
}
.private-page-container.success-message .content a {
  text-decoration: underline;
  color: #d61f79;
}
.private-page-container.success-message .content a:hover {
  color: #c01c6c;
}
.private-page-container.success-message .content a:active {
  color: #a91960;
}
.private-page-container.reset-password form {
  margin: 100px 0;
}
@media (max-width: 1024px) {
  .private-page-container {
    min-height: calc(80vh - 100px);
  }
  .private-page-container .header {
    padding: 20px;
    margin-left: -16px;
    margin-right: -16px;
  }
  .private-page-container .page-desc {
    margin-top: 60px;
  }
  .private-page-container form p {
    max-width: unset;
  }
  .private-page-container.forgot-page .page-desc {
    margin-bottom: 56px;
  }
  .private-page-container.registration .sc-radio-group {
    grid-gap: 10px;
  }
  .private-page-container.registration .sc-radio-group .form-check label {
    padding: 20px 15px 20px 45px;
  }
  .private-page-container.registration .captcha-wrapper img {
    width: 128px;
    height: 42px;
  }
  .private-page-container.registration .captcha-wrapper input {
    width: 150px;
  }
}
@media (max-width: 768px) {
  .private-page-container form .input-wrapper {
    width: 100%;
  }
  .private-page-container form .input-wrapper .form-control {
    width: 100%;
  }
  .private-page-container form .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .private-page-container form .error {
    min-height: 26px;
  }
  .private-page-container form .form-submit {
    margin-top: 40px;
  }
  .private-page-container form .customer-title {
    margin-top: 50px;
  }
  .private-page-container form .create-account {
    margin-top: 20px;
  }
  .private-page-container form .forgot {
    margin-top: 14px;
  }
  .private-page-container.registration form {
    width: 100%;
  }
  .private-page-container.registration .sc-input-group {
    width: 100%;
    margin-top: 56px;
  }
  .private-page-container.registration .captcha-wrapper input {
    flex: 1;
  }
  .private-page-container.registration .form-submit {
    margin-top: 20px !important;
  }
  .private-page-container.reset-password form {
    margin: 50px 0;
  }
}
@media (max-width: 480px) {
  .private-page-container.registration .sc-input-group {
    grid-template-columns: 1fr;
  }
}

#signInUpModal .modal-dialog {
  max-width: 760px;
}
@media (max-width: 768px) {
  #signInUpModal .modal-dialog {
    max-width: 400px;
  }
}
#signInUpModal .modal-content {
  padding: 0 !important;
  border-radius: 20px !important;
  border: none !important;
}
#signInUpModal .modal-content .modal-body {
  position: relative;
  padding: 0 !important;
}
#signInUpModal .modal-content .modal-body .join-now-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  min-height: 420px;
  position: relative;
}
#signInUpModal .modal-content .modal-body .join-now-container .description {
  display: grid;
  justify-content: center;
  grid-gap: 20px;
  grid-template-rows: auto auto 1fr auto;
  background: #000000;
  border-radius: 20px;
  color: #ffffff;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
}
#signInUpModal .modal-content .modal-body .join-now-container .description > * {
  max-width: 210px;
  word-break: break-word;
}
#signInUpModal .modal-content .modal-body .join-now-container .description h2 {
  display: grid;
  font-weight: bold;
  font-size: calc(18px + var(--big-fs));
  line-height: 24px;
}
#signInUpModal .modal-content .modal-body .join-now-container .description p {
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
#signInUpModal .modal-content .modal-body .join-now-container .description ul {
  margin-bottom: 18px;
}
#signInUpModal .modal-content .modal-body .join-now-container .description ul li {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: calc(18px + var(--big-fs));
  line-height: 24px;
}
#signInUpModal .modal-content .modal-body .join-now-container .description ul li i {
  margin-right: 20px;
  font-size: calc(24px + var(--big-fs));
  line-height: 30px;
}
#signInUpModal .modal-content .modal-body .join-now-container .description ul li:not(:last-child) {
  margin-bottom: 20px;
}
#signInUpModal .modal-content .modal-body .join-now-container .description a {
  width: 210px !important;
  justify-content: center;
  margin: 0 auto;
}
#signInUpModal .modal-content .modal-body .join-now-container .auth-content {
  display: grid;
  justify-content: center;
  grid-gap: 20px;
  padding: 50px;
  height: 100%;
  border-radius: 20px;
  grid-template-rows: 1fr auto;
}
#signInUpModal .modal-content .modal-body .join-now-container .auth-content h2 {
  display: grid;
  font-weight: bold;
  margin-top: 24px;
  font-size: calc(18px + var(--big-fs));
  line-height: 24px;
}
#signInUpModal .modal-content .modal-body .join-now-container .auth-content form {
  display: grid;
  width: 210px;
}
#signInUpModal .modal-content .modal-body .join-now-container .auth-content form .form-control {
  width: 100%;
  border-radius: 8px;
}
#signInUpModal .modal-content .modal-body .join-now-container .auth-content form .input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#signInUpModal .modal-content .modal-body .join-now-container .auth-content form .input-group input {
  padding: 2px 15px !important;
}
#signInUpModal .modal-content .modal-body .join-now-container .auth-content form .input-group .error {
  color: #ff4869;
  min-height: 20px;
  padding-top: 2px;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
#signInUpModal .modal-content .modal-body .join-now-container .auth-content form .forgot {
  width: fit-content;
  margin: 0 auto;
}
#signInUpModal .modal-content .modal-body .join-now-container .auth-content form label {
  display: flex;
  align-items: center;
}
#signInUpModal .modal-content .modal-body .join-now-container .auth-content form .form-check-input {
  border-color: #d61f79;
}
#signInUpModal .modal-content .modal-body .join-now-container .auth-content form .btn {
  margin: 26px auto 0;
  width: 100% !important;
  justify-content: center;
}
#signInUpModal .modal-content .modal-close {
  position: absolute;
  top: 30px;
  right: 30px;
  color: #000000;
  background: #ffffff;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(18px + var(--big-fs));
  line-height: 24px;
}
@media (max-width: 768px) {
  #signInUpModal .modal-content .modal-body .join-now-container {
    height: fit-content;
    grid-template-columns: 1fr;
  }
  #signInUpModal .modal-content .modal-body .join-now-container .description {
    width: 100% !important;
    margin: 0 auto;
    grid-gap: 20px;
    padding: 70px 40px 48px;
  }
  #signInUpModal .modal-content .modal-body .join-now-container .description > * {
    max-width: 100%;
  }
  #signInUpModal .modal-content .modal-body .join-now-container .auth-content {
    position: relative;
    top: -48px;
    grid-gap: 30px;
    padding: 98px 40px 0;
  }
  #signInUpModal .modal-content .modal-body .join-now-container .auth-content form {
    width: 100%;
    max-width: 350px !important;
  }
  #signInUpModal .modal-content .modal-body .join-now-container .description,
  #signInUpModal .modal-content .modal-body .join-now-container .auth-content {
    justify-content: flex-start;
    grid-template-columns: 1fr;
  }
  #signInUpModal .modal-content .modal-body .join-now-container .description h2,
  #signInUpModal .modal-content .modal-body .join-now-container .auth-content h2 {
    margin-top: 0;
    font-size: calc(18px + var(--big-fs));
    line-height: 24px;
  }
  #signInUpModal .modal-content .modal-body .join-now-container .description ul li,
  #signInUpModal .modal-content .modal-body .join-now-container .description p,
  #signInUpModal .modal-content .modal-body .join-now-container .auth-content ul li,
  #signInUpModal .modal-content .modal-body .join-now-container .auth-content p {
    font-size: calc(16px + var(--big-fs));
    line-height: 22px;
  }
  #signInUpModal .modal-content .modal-body .join-now-container .description .btn,
  #signInUpModal .modal-content .modal-body .join-now-container .auth-content .btn {
    max-width: unset !important;
    width: 100% !important;
  }
  #signInUpModal .modal-content .modal-body .join-now-container .description .input-group:first-child,
  #signInUpModal .modal-content .modal-body .join-now-container .auth-content .input-group:first-child {
    margin-bottom: 16px !important;
  }
  #signInUpModal .modal-content .modal-close {
    color: #ffffff;
    background: #000000;
  }
}
